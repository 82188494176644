import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PageProps } from 'gatsby';
import React from 'react';
import { useRef } from 'react';
import useSWR from 'swr';
import EmptyGameList from '../../../components/EmptyGameList';
import FloatingUpButton from '../../../components/FloatingUpButton';
import GameListItem from '../../../components/GameListItem';
import Layout from '../../../components/Layout';
import LoadingSpinner from '../../../components/LoadingSpinner';
import NavigationBar from '../../../components/NavigationBar';
import { displaySmall } from '../../../styles';
import { changeEnableScroll } from '../../../utils/bridge';
import getCreator from '../../../apis/core/getCreator';
import { swrDefaultOption } from '../../../utils/swr';

export default function ViewAll({ params }: { params: { creatorId: string } }) {
  const { creatorId } = params;

  React.useEffect(() => {
    changeEnableScroll(true);
  }, []);
  const ref = useRef<HTMLUListElement>(null);

  const { data: creator } = useSWR<Creator>(
    'getCreator',
    () => getCreator(creatorId),
    swrDefaultOption
  );

  return (
    <Layout>
      <NavigationBar>크리에이터 홈</NavigationBar>
      {creator && creator.projects ? (
        <ListContainer ref={ref}>
          <h2
            css={[
              displaySmall,
              css`
                padding: var(--xlarge2) 0 var(--medium);
              `,
            ]}
          >
            {creator && creator.name}님의 게임
          </h2>
          {creator &&
            creator.projects?.map((project: ProjectLite, index) => (
              <li key={project.id}>
                <GameListItem index={index} type="vertical" game={project} />
              </li>
            ))}
          {creator && creator.projects.length === 0 && (
            <EmptyGameList
              css={css`
                margin-bottom: 8.5rem;
              `}
            />
          )}
          {creator && creator.projects.length > 0 && (
            <FloatingUpButton scrollContainerRef={ref} />
          )}
        </ListContainer>
      ) : (
        <LoadingSpinner />
      )}
    </Layout>
  );
}

const ListContainer = styled.ul`
  flex: 1;
  width: 100%;

  overflow-y: auto;

  padding: 0 var(--large);
  display: flex;
  flex-direction: column;

  list-style: none;

  > li:not(:last-of-type) {
    border-bottom: 1px solid var(--gray-tintz-2);
  }
`;
